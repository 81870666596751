@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.modal {
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 6;

		@include gridle_state(md) {
			justify-content: center;
		}
	}

	&__opacity-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $black;
		opacity: 0.7;
		z-index: 8;
	}

	&__background {
		background: none !important;
	}

	&__paper {
		z-index: 10;
		position: relative;
		background: white;
		border-radius: $border-radius-big;
		width: calc(100vw - 20px);
		margin: 0 10px;

		@include gridle_state(md) {
			width: unset;
			min-width: 500px;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 80px;
	}

	&__title {
		height: 20px;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.22;
		color: $black;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__icon-container {
		height: 14px;
		width: 14px;
		padding: 10px;
		align-self: flex-start;

		@include gridle_state(md) {
			padding: 20px;
			height: 16px;
			width: 16px;
		}
	}

	&__content {
		padding: 0 20px 20px 20px;
		@include gridle_state(md) {
			padding: 0 85px 40px 85px;
		}
	}
}
