@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.phone-number-sva {
	width: 266px;
	height: 32px;

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	border: 2px solid #e1e1e1;
	background-color: #ffffff;

	&__link {
		text-decoration: inherit;
	}

	&__number {
		flex: 1;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	&__number-content {
		width: 128px;

		font-family: Arial;
		font-size: 1.4em;
		font-weight: bold;
	}

	&__pricing {
		margin-right: 7px;
		padding-left: 5px;

		width: 102px;
		height: 38px;

		text-align: left;
		position: relative;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	&__pricing::after {
		content: "";
		position: absolute;
		right: 0;
		left: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 0 8px 8px;
		border-color: transparent transparent transparent white;
	}

	&__pricing-content {
		font-family: Arial;
		font-size: 0.8em;
		font-weight: bold;
		color: #ffffff;
	}

	&__entire-line {
		white-space: pre;
	}

	&__pricing-time {
		font-family: Arial;
		font-size: 0.8em;
		font-weight: bold;
	}
}
