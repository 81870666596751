@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.frequency-modal {
	position: relative;
	background: white;
	padding: 40px 15px 25px;
	border-radius: 10px;

	&__close {
		position: absolute;
		top: 15px;
		right: 15px;

		.icon {
			width: 14px;
			height: 14px;
		}
	}

	@include gridle_state(md) {
		padding: 25px 25px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 670px;
		height: 430px;

		.newsletter-frequency-form {
			width: 512px;
		}

		&__close {
			top: 35px;
			right: 35px;

			.icon {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		}
	}
}
