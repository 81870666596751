@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.newsletter-frequency-form {
	&__header {
		text-align: center;

		.separator {
			margin-top: 5px;
			margin-bottom: 15px;
			width: 35px;
			height: 1.5px;
			background-color: $color-secondary;

			@include gridle_state(md) {
				margin-bottom: 30px;
			}
		}
	}

	&__title {
		@extend %font-bold;
		color: $color-secondary;
		text-transform: uppercase;
		font-size: 1.7rem;
		letter-spacing: 0.85px;

		@include gridle_state(md) {
			font-size: 2rem;
		}
	}

	&__intro {
		@extend %font-bold;
		color: $color-primary;
	}

	&__form {
		margin-top: 20px;

		.radio {
			padding: 14px;
			margin-bottom: 14px;
		}

		@include gridle_state(md) {
			margin-top: 30px;

			.radio {
				margin-bottom: 20px;
			}
		}
	}

	&__label {
		@extend %font-bold;
		color: $color-primary;
	}

	&__sublabel {
		@extend %text-secondary;
	}

	&__button {
		text-align: center;
		margin-top: 10px;

		@include gridle_state(md) {
			margin-top: 30px;

			.button {
				width: 220px;
			}
		}
	}

	&__link {
		@extend %text-secondary;
		margin-top: 20px;
		text-align: center;
		text-decoration: underline;
		cursor: pointer;

		@include gridle_state(md) {
			margin-top: 30px;
		}
	}
}
