@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.info-notice {
	background: #dff2fd;
	color: $color-info;
	padding: 15px;

	&__header {
		display: flex;
		gap: 15px;
		margin-bottom: 15px;
		align-items: center;
	}

	&__icon {
		width: 15px;
		height: 15px;
	}
}
