@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$hamburger-layer-color: $black !default;
$header-text-color: $black !default;

.hamburger-account {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	.icon-account__wpr,
	svg {
		height: 27px;
		width: 21px;
		color: $hamburger-layer-color;
	}

	&__label {
		margin-top: 8px;
		color: $header-text-color;
	}

	@include gridle_state(md) {
		.icon-account__wpr,
		svg {
			height: 30px;
			width: 30px;
		}
	}
}
